<!-- 提现 -->
<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="withdraw-container nav-page-container">
      <van-form
          @submit="onSubmit"
          label-width="7.5em"
          input-align="right"
      >
        <div class="withdraw-card-item">
          <!--
                  <van-field
                      readonly
                      clickable
                      is-link
                      name="bank"
                      label="收款银行"
                      :value="form.bank"
                      placeholder="选择收款银行"
                      :rules="[{ required: true, message: '请选择收款银行' }]"
                      @click="showPicker = true"
                  />
                  <van-popup v-model="showPicker" round position="bottom">
                    <van-picker
                        show-toolbar
                        :columns="bankList"
                        value-key="label"
                        @cancel="showPicker = false"
                        @confirm="({ label }) => {
                        this.form.bank = label;
                        this.showPicker = false;
                      }"
                    />
                  </van-popup>
          -->
          <van-field
              v-model.trim="form.bank"
              name="bankCardNo"
              readonly
              label="收款银行"
          />

          <van-field
              v-model.trim="form.bankCardNo"
              name="bankCardNo"
              readonly
              label="收款银行卡号"
          />
          <van-field
              v-model.trim="form.fullName"
              name="fullName"
              readonly
              label="收款人姓名"
          />

          <van-field
              v-model.trim="form.mobilePhone"
              name="mobilePhone"
              readonly
              label="手机号码"
          />

          <van-field
              v-model.trim="form.amount"
              name="amount"
              type="number"
              right-icon="gold-coin-o"
              label="提现金额"
              placeholder="提现金额"
              :rules="[
                { required: true, message: '请输入提现金额' },
                { validator: val => !!+val, message: '请输入提现金额' },
                { pattern: /^([1-9]\d{0,9}|0)(\.\d{1,2})?$/, message: '请输入正确的提现金额' },
            ]"
          />
        </div>
        <p>余额: {{ balance }}</p>

        <div class="submit-container">
          <van-button round block type="primary" native-type="submit">提现</van-button>
        </div>
      </van-form>
    </page-container>
  </div>
</template>

<script>

import { getBankList, getUserAccount, getUserInfo } from '@/api/user';
import { cashOutRecord, cashOutRecordInfo, cashOutRecordSubmit } from '@/api/withdrawal';
import { Dialog, Toast } from 'vant';

const defaultForm = () => ({
  bank: '', // 收款银行
  bankCardNo: '', // 收款银行卡号
  fullName: '', // 收款人姓名
  mobilePhone: '', // 收款人手机
  amount: '', // 提现金额
});
export default {
  name: 'withdraw',
  data() {
    return {
      balance: '', // 用户余额
      showPicker: false,
      form: defaultForm(),
    };
  },
  created() {
    getUserInfo().then(res => {
      if (res.status !== '02')
        Dialog.alert({
          message: `提现需要完善您的个人信息`,
          confirmButtonText: '去完善',
        }).then(() => {
          this.$router.replace('/userInfo');
        });
    });

    cashOutRecordInfo().then(res => {
      let {
        bankName: bank,
        bankAccountNo: bankCardNo,
        bankAccountName: fullName,
        bankAccountPhone: mobilePhone,
        amount,
      } = res || {};
      this.balance = amount;
      this.form = {
        bank, // 收款银行
        bankCardNo, // 收款银行卡号
        fullName, // 收款人姓名
        mobilePhone, // 收款人手机
      };
    });
    // getBankList().then(res => {
    //   this.bankList = res || [];
    // });
  },
  methods: {
    onSubmit() {
      let amount = +this.form.amount;
      let balance = +this.balance;

      if (!balance || amount >= balance) {
        return Toast('余额不足');
      }

      Dialog.confirm({
        title: `是否确认提现？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            cashOutRecordSubmit({ amount }, { headers: { getAllResponse: true } }).then(res => {
              done();
              if (res.data.code === 0) {
                Toast(`您已成功提现￥${amount}`);
                this.$router.back();
              } else {
                Toast(res.data.msg);
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
  },

};
</script>

<style lang="less" scoped>
.withdraw-container {
  .withdraw-card-item {
    margin: 10px;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
  }

  .submit-container {
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 100%;
  }

  p {
    margin: 10px 0 0 0;
    padding: 0 30px;
    line-height: 2;
    text-align: right;
    font-size: 14px;
    color: #888;
  }

}
</style>
