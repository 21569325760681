import request from '@/plugins/axios';

/**
 * 用户提现旧
 */
export function cashOutRecord(data, opts) {
  return request({
    url: `shop-mobile/cashOutRecord/${data.amount}`,
    method: 'put',
    ...opts,
    data
  });
}

/**
 * 用户提现新
 */
export function cashOutRecordSubmit(data = {}, opts) {
  return request({
    url: 'shop-mobile/cashOutRecord/submit?amount=' + data.amount,
    method: 'post',
    ...opts,
    data,
  });
}


/**
 * 提现记录
 */
export function getCashOutRecordByPage(params) {
  return request({
    url: `shop-mobile/cashOutRecord/page`,
    method: 'get',
    params
  });
}


/**
 * 提现用户信息
 * shop-mobile/cashOutRecord/info
 */
export function cashOutRecordInfo(params = {}) {
  return request({
    url: `shop-mobile/cashOutRecord/info`,
    method: 'get',
    params,
  });
}
